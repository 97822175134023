/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
import LogoDark from '../images/logo-dark.png'

const Seo = ({ description, lang, meta, title, origin, pathname, type, image: metaImage }) => {
  const siteMetadata= {
    siteUrl: 'https://www.fieldnext.io',
    title: 'FieldNext',
    author: 'DotNext Technologies Pvt. Ltd.',
    description: 'FieldNext offers the latest mobile-enabled software for field employee tracking, monitoring, reporting, attendance, expense, visit, and task management.'
  }

  const metaDescription = description || siteMetadata.description
  const metaTitle = title || siteMetadata.title
  const canonical = pathname ? origin ? `${origin}${pathname}` : `${siteMetadata.siteUrl}${pathname}` : null
  const image =
    metaImage && metaImage.src
      ? `${siteMetadata.siteUrl}${metaImage.src}`
      : null

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
        }
        meta={[
          { name: 'initial-scale', content: '1.0' },
          { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },
          { itemprop: 'name', content: metaTitle },
          { itemprop: 'description', content: metaDescription },
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: `author`,
            content: siteMetadata?.author || ``,
          },
          {
            property: `og:title`,
            content: metaTitle,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: type || `website`,
          },
          {
            name: `twitter:card`,
            content: `summary`,
          },
          {
            name: `twitter:creator`,
            content: siteMetadata?.social?.twitter || ``,
          },
          {
            name: `twitter:title`,
            content: metaTitle,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
        ].concat(meta)}
    >
      <script type='application/ld+json'>
          {image ? `{
              '@context': 'https://schema.org',
              '@type': '${type ? type : 'Product'}',
              '@id': 'https://fieldnext.io',
              'name': 'FieldNext',
              'url':'${canonical}',
              'headline': '${metaTitle}',
              'description': '${metaDescription}',
              'image': '${image}'
          }`
          :
          `{
            '@context': 'https://schema.org',
            '@type': '${type ? type : 'Product'}',
            '@id': 'https://fieldnext.io',
            'name': 'FieldNext',
            'url':'${canonical}',
            'headline': '${metaTitle}',
            'description': '${metaDescription}'
        }`
        }
        </script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  pathname: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string.isRequired,
    height: PropTypes.number.isRequired,
    width: PropTypes.number.isRequired,
  }),
}

export default Seo
